

export default {
    methods: {
        getNotesShutters(props) {
            return {
				'note-selection': {
                    name: 'note-selection',
                    title: this.$t('notes.note_selection'),
                    cmpPath: 'ShutterGroomyRoot/Notes/NoteSelection',
                    onOk: (selected) => {
                        this.handleNotePreview([{ note_id: selected[0] }])
                    },
                    props
                },
				'note-preview': {
                    name: 'note-preview',
                    title: this.$t('notes.infos_note'),
                    cmpPath: 'ShutterGroomyRoot/Notes/NotePreview',
                    props
                }
            }
        },

		setupNoteSelection(opts={}) {
			if(opts.notes) {
				const shutters = this.getNotesShutters({ notes: opts.notes, ...opts.shutterProps })
				this.shutterPanel().open(shutters['note-selection'])
			}
			else if(opts.horse_id) {
				// 
			}
		},

		setupNotePreview(note_id) {
			const shutters = this.getNotesShutters({ note_id })
            this.shutterPanel().open(shutters['note-preview'])
		},

		handleNotePreview(notes) {
			if(notes.length > 1) {
				this.setupNoteSelection({ 
					notes,
					shutterProps: {
						unique: true
					}
				})
				return
			}
			this.setupNotePreview(notes[0].note_id)
		}
    }
}
